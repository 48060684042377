import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import GenericPage from '../../components/genericPage'

import content from '../../json/2021/accessibilityContent.json'

const AccessibilityContentPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/demarche-accessibilite">
      <SEO 
        title="Démarche accessibilité de l’Observatoire de l'impact positif - 2021"
        description="L’Observatoire de l'impact positif des marques françaises a été développé pour répondre en partie aux préconisations d’accessibilité. Découvrez notre démarche."
        path="/2021/demarche-accessibilite"
        year="2021" />

      <Breadcrumb url="/2021/demarche-accessibilite" label="Démarche accessibilité" type="credits" year="2021" /> 

      <GenericPage slug="accessibilityContent" content={content} />
    </Layout>
  )
  }

export default AccessibilityContentPage

